import { useEffect, useState, useContext } from "react";
import {
  formatNumber,
  txColor,
  Greeting,
  formatLocalTime,
  naira,
  copy,
} from "../../utils/helpers";
import { Context } from "../../context/contex";
import Ads from "../../components/Ads";
import NoAuth from "../../components/no-auth";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import { Link } from "react-router-dom";

import { Container, Row, Col, Button, Card, ListGroup } from "react-bootstrap";

const Dashboard = () => {
  const [vcode, setVcode] = useState(null);

  const context = useContext(Context);

  const { userInfo, transactions, validUser, domain } = context;

  useEffect(() => {
    const checkcode = async () => {
      if (localStorage?.getItem("swapekis_email_code") == null) {
        return;
      } else {
        setVcode(localStorage?.getItem("swapekis_email_code"));
      }
    };
    checkcode();
  }, [localStorage]);

  return (
    <>
      {validUser && <Header userInfo={userInfo} />}
      <Container fluid className="dashboard-container">
        {/* Greeting and Profile */}
        {validUser && (
          <>
            <Row className="align-items-center text-center">
              <Col className="text-white ml-3" xs={12}>
                <h5>
                  {" "}
                  <Greeting />
                </h5>
                <h2>{userInfo?.first_name.toUpperCase()}!</h2>
              </Col>
            </Row>

            {/* Balance Card */}

            <Row>
              <Col className="text-center text-white">
                <Card className="balance-card mt-3">
                  <Card.Body>
                    <h6>Total Balance</h6>
                    <h2>
                      {naira}
                      {formatNumber(userInfo?.ngn_balance.toString())}
                    </h2>
                    <Button
                      variant="light"
                      as={Link}
                      to="/deposit"
                      className="mt-2"
                    >
                      FUND ACCOUNT <i className="fa fa-credit-card"></i>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="text-center text-white">
                <Card className="balance-card mt-3">
                  <Card.Body>
                    <h6>Referral Bonus</h6>
                    <h2>
                      {naira}
                      {formatNumber(userInfo?.ref_bonus.toString())}
                    </h2>
                    <Button
                      variant="light"
                      className="mt-2"
                      onClick={() => copy(domain + "register?r=" + userInfo.id)}
                    >
                      COPY REFERRAL LINK <i className="fa fa-copy"></i>
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Quick Action Buttons */}
            <Row className="quick-actions mt-4">
              <Col className="text-center text-white" as={Link} to="/top-up">
                <Button variant="light" className="rounded-circle action-btn">
                  <i className="fa fa-mobile"></i>
                </Button>
                <p>Top Up</p>
              </Col>
              <Col className="text-center text-white" as={Link} to="/pay">
                <Button variant="light" className="rounded-circle action-btn">
                  <i className="fa fa-barcode"></i>
                </Button>
                <p>Pay</p>
              </Col>
              <Col className="text-center text-white" as={Link} to="/trade">
                <Button variant="light" className="rounded-circle action-btn">
                  <i className="fa fa-exchange"></i>
                </Button>
                <p>Crypto</p>
              </Col>
              <Col className="text-center text-white">
                <Button variant="light" className="rounded-circle action-btn">
                  <i className="fa fa-ellipsis-h"></i>
                </Button>
                <p>More</p>
              </Col>
            </Row>

            {/* Expense Card */}
            <Row className="my-4 text-center">
              <Col>
                <Ads />
              </Col>
            </Row>

            {/* To Dos */}
            {userInfo?.email_verified === 0 ||
            userInfo?.ngn_balance === "0" ||
            userInfo?.tx_pin == null ||
            userInfo.photo == null ||
            userInfo.withdrawal_bank_account == null ? (
              <Row className="align-items-center">
                <Col xs="12">
                  <h5 className="text-uppercase">TO DOs</h5>
                </Col>

                {userInfo?.email_verified === 0 && vcode != null && (
                  <Col xs="12">
                    <Button
                      variant="link"
                      className="text-danger"
                      as={Link}
                      to="/verify-email"
                    >
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>

                      <b>Verify your email</b>
                    </Button>
                  </Col>
                )}

                {userInfo?.ngn_balance === "0" && (
                  <Col xs="12">
                    <Button
                      variant="link"
                      className="text-danger"
                      as={Link}
                      to="/deposit"
                    >
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      <b>Add money</b>
                    </Button>
                  </Col>
                )}

                {userInfo?.tx_pin == null && (
                  <Col xs="12">
                    <Button
                      variant="link"
                      className="text-danger"
                      as={Link}
                      to="/set-pin"
                    >
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      <b>Set your PIN</b>
                    </Button>
                  </Col>
                )}

                {/*
                userInfo?.id_verified === 0 && (
                  <Col xs="12">
                    <Button
                      variant="link"
                      className="text-danger"
                      as={Link}
                      to="/kyc"
                    >
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      <b>Verify your identity</b>
                    </Button>
                  </Col>
                )
                */}

                {userInfo?.photo == null && (
                  <Col xs="12">
                    <Button
                      variant="link"
                      className="text-danger"
                      as={Link}
                      to="/settings"
                    >
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      <b>Add profile picture</b>
                    </Button>
                  </Col>
                )}

                {/*
                userInfo?.username == null && (
                  <Col xs="12">
                    <Button
                      variant="link"
                      className="text-danger"
                      as={Link}
                      to="/edit-profile"
                    >
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      <b>Set a username</b>
                    </Button>
                  </Col>
                )
                */}

                {userInfo?.withdrawal_bank_account == null && (
                  <Col xs="12">
                    <Button
                      variant="link"
                      className="text-danger"
                      as={Link}
                      to="/settings"
                    >
                      <i
                        className="fa fa-exclamation-circle mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      <b>Link a bank</b>
                    </Button>
                  </Col>
                )}
              </Row>
            ) : null}

            {/* Recent Transactions */}
            <Row className="align-items-center">
              <Col xs="9">
                <h3>Recent Transactions</h3>
              </Col>
            </Row>
            {transactions.length > 0 ? (
              <div className="recent-transactions mt-4">
                <ListGroup variant="flush" className="mt-3">
                  {transactions.map((tx, index) => (
                    <ListGroup.Item
                      key={`tx_${index}`}
                      className="d-flex justify-content-between"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(`/transaction?tx=${tx.our_ref}`)
                      }
                    >
                      <div>
                        <h6>{tx.title}</h6>
                        <br />
                        <small>{formatLocalTime(tx.date)}</small>
                      </div>
                      <div>
                        <h6>
                          {naira}
                          <b>{formatNumber(tx.amount)}</b>
                        </h6>
                        <small className={txColor(tx.status)}>
                          {tx.status}
                        </small>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            ) : (
              <div className="text-center my-5">
                <img src="assets/images/fly.gif" height={80} />
                <p>No transactions found.</p>
                <p>&nbsp;</p>
              </div>
            )}
          </>
        )}
        {!validUser && <NoAuth />}
      </Container>
      {validUser && <Footer />}
    </>
  );
};

export default Dashboard;
